import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Appointment, Page } from "@/types";

type AppointmentState = {
  appointmentPage: Page<Appointment>;
  appointmentCount: number;
};

const appointment: Module<AppointmentState, unknown> = {
  namespaced: true,
  state: () => ({
    appointmentPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    appointmentCount: 0,
  }),
  getters: {
    getAppointment: (state) => (appointmentId: string) =>
      state.appointmentPage.docs.find((c) => c._id === appointmentId),
    appointmentPage: (state) => state.appointmentPage,
    appointmentCount: (state) => state.appointmentCount,
  },
  mutations: {
    ADD_APPOINTMENT: (state, _appointment) => {
      let idx = -1;
      state.appointmentPage.docs.map((a, i) => {
        if (a._id === _appointment._id) idx = i;
      });
      if (idx === -1) state.appointmentPage.docs.push(_appointment);
      else Vue.set(state.appointmentPage.docs, idx, _appointment);
    },
    SET_APPOINTMENT_PAGE: (state, list) => {
      state.appointmentPage = list;
    },
    REMOVE_APPOINTMENT(state, appointment) {
      let idx = -1;
      state.appointmentPage.docs.map((a, i) => {
        if (a._id === appointment._id) idx = i;
      });
      if (idx > -1) state.appointmentPage.docs.splice(idx, 1);
    },
    SET_APPOINTMENT_COUNT(state, count) {
      state.appointmentCount = count;
    },
  },
  actions: {
    async fetchAppointment(context, params = "") {
      return await api
        .get(`/v1/appointment${params}`)
        .then((response) => {
          context.commit("ADD_APPOINTMENT", response.data.appointment);
          return response.data.appointment;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchAppointmentList(context, params = "") {
      context.commit("SET_APPOINTMENT_PAGE", {
        docs: [],
        limit: 0,
        page: 0,
        pages: 0,
        sort: "",
        total: 0,
      });
      return await api
        .get(`/v1/appointment${params}`)
        .then((response) => {
          context.commit("SET_APPOINTMENT_PAGE", response.data.appointmentPage);
          return response.data.appointmentPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createAppointment(context, payload) {
      return await api
        .post(`/v1/appointment`, payload)
        .then((response) => {
          context.commit("ADD_APPOINTMENT", response.data.appointment);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Appointment created",
            },
            { root: true }
          );
          return response.data.appointment;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateAppointment(
      context,
      data: { id: string; appointment: Appointment }
    ) {
      return await api
        .put(`/v1/appointment/${data.id}`, data.appointment)
        .then((response) => {
          context.commit("ADD_APPOINTMENT", response.data.appointment);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Appointment updated",
            },
            { root: true }
          );
          return response.data.appointment;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteAppointment(context, id) {
      api
        .delete(`/v1/appointment${id}`)
        .then((response) => {
          context.commit("REMOVE_APPOINTMENT", response.data.appointment);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Appointment deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchAppointmentCount(context, params = "") {
      api
        .get(`/v1/appointment/count${params}`)
        .then((response) => {
          context.commit(
            "SET_APPOINTMENT_COUNT",
            response.data.appointmentCount
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async bulkCreateAppointment(context, payload) {
      return await api
        .post<{ appointments: Appointment[] }>(`/v1/appointment/bulk`, payload)
        .then((response) => {
          response.data.appointments.map((a) =>
            context.commit("ADD_APPOINTMENT", a)
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Recurring Appointments created",
            },
            { root: true }
          );
          return response.data.appointments;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    async retryAppointmentPayment(context, payload) {
      return await api
        .put(`/v1/order/retry-appointment-payment/${payload.id}`, {
          payment: payload.payment,
        })
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Processing Payment",
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default appointment;
