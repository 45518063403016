




























import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";

import paystackStoreModule from "@/store/modules/paystack";
import { Role } from "@/types";

const { mapActions: paystackPlanActions } =
  createNamespacedHelpers("SUBSCRIPTION");
export default Vue.extend({
  name: "AddOnForm",
  props: {
    feature: {
      type: String, // marketing or membership
      required: true,
    },
  },
  data: () => ({
    // modal: false,
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    nextBillingDate() {
      return moment().add(1, "month").format("DD/MM/YYYY");
    },
  },
  methods: {
    ...paystackPlanActions(["updateVendorPlan"]),
    buy() {
      //
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SUBSCRIPTION")) {
      this.$store.registerModule("SUBSCRIPTION", paystackStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SUBSCRIPTION");
  },
});
