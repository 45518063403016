









































































































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import appointmentStoreModule from "@/store/modules/appointment";
import clientStoreModule from "@/store/modules/client";
import employeeStoreModule from "@/store/modules/employee";
import serviceStoreModule from "@/store/modules/service";
import checkout from "@/store/modules/checkout";
import locationStoreModule from "@/store/modules/location";
import consultationFormStoreModule from "@/store/modules/clientConsultationForm";
import { Business, Client, Role, Service, _Location } from "@/types";
import { DATE_REGEX } from "@/util/constants";

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("EMPLOYEES_");

const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("SERVICES_");

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("CLIENTS_");

const { mapActions: appointmentActions } = createNamespacedHelpers(
  "CALENDAR_APPOINTMENTS"
);

const { mapActions: locationActions } = createNamespacedHelpers(
  "APPOINTMENT_LOCATIONS"
);

const { mapGetters: formGetters, mapActions: formActions } =
  createNamespacedHelpers("CLIENT_CONSULTATION_FORMS");

const { mapActions: cartActions } = createNamespacedHelpers("CART_");

// export function debounce(
//   func: any,
//   wait: number,
//   immediate: boolean
// ): () => void {
//   var timeout: any;
//   return () => {
//     // eslint-disable-next-line @typescript-eslint/no-this-alias
//     var context = this,
//       args = arguments;
//     var later = function () {
//       timeout = null;
//       if (!immediate) func.apply(context, args);
//     };
//     var callNow = immediate && !timeout;
//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//     if (callNow) func.apply(context, args);
//   };
// }

export default Vue.extend({
  //
  name: "AppointmentForm",
  props: {
    inputDate: {
      type: String,
      required: false,
    },
    inputTime: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      valid: false,
      date: "",
      time: "",
      employeeId: "",
      selectedServiceIds: [],
      selectedServices: [] as Service[],
      clientId: "",
      location: "",
      notes: "",
      dateRules: [
        (v: string) => !!v || "Date is required",
        (v: string) => DATE_REGEX.test(v) || "Date must be valid format",
      ],
      timeRules: [(v: string) => !!v || "Time is required"],
      staffRules: [(v: string) => !!v || "Staff is required"],
      clientRules: [(v: string) => !!v || "Client is required"],
      serviceRules: [(v: string) => !!(v && v.length) || "Service is required"],
      locations: [] as string[],
      isRecurring: false,
      recurringOptions: [
        { label: "Weekly", value: "week" },
        { label: "Monthly", value: "month" },
        { label: "Fortnightly", value: "14-day" },
        { label: "Every 6 Months", value: "6-month" },
      ],
      recurringPeriod: "" as "week" | "month" | "14-day" | "6-month",
      duration: 0,
      durationRules: [(v: string) => !!v || "Duration is required"],
      showServiceDurationSelectionDialog: false,
      service: undefined as undefined | Service,
      showConsultationFormDialog: false,
      formId: "",
      isLoadingLocations: false,
      customLocation: "",
      dateFormatted: "",
      dateMenu: false,
      recurringDuration: 1,
      recurringPeriodRules: [
        (v: string) => !!v || "Recurring perion is required",
      ],
      paymentDialog: false,
      mode: "",
    };
  },
  watch: {
    clientId() {
      if (this.clientId) {
        this.formId = "";
        this.fetchClientConsultationList(`?clientId=${this.clientId}`);
      }
    },
    duration: "getEmployees",
    dateFormatted: "getEmployees",
    time: "getEmployees",
  },
  mounted() {
    this.fetchData();
    this.resetForm();
  },
  computed: {
    ...clientGetters(["clientPage"]),
    ...employeeGetters(["employeePage"]),
    ...serviceGetters(["servicePage"]),
    ...formGetters(["clientConsultationPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    serviceDate(): string {
      if (this.time && this.dateFormatted) {
        let date: any = this.dateFormatted;

        date = (date as string).split("/");
        const [hr, min] = this.time.split(":");

        const startDate = new Date(
          date[2],
          date[1] - 1,
          date[0],
          +hr,
          +min
        ).toISOString();
        return startDate;
      }
      return "";
    },
    client(): Client {
      return this.$store.getters["CLIENTS_/getClient"](this.clientId);
    },
    deposit(): number {
      let deposit = 0;
      this.selectedServices.forEach((_service) => {
        if (
          _service.advancedPricing.length &&
          _service.deposit &&
          _service.requiresDeposit
        ) {
          const _price = _service.advancedPricing[0].price;

          if (_service.deposit?.amountType === "percentage") {
            deposit = (_service.deposit?.amount / 100) * _price;
          } else {
            deposit = _service.deposit?.amount || 0;
          }
        }
      });

      return deposit;
    },
  },
  methods: {
    ...appointmentActions([
      "createAppointment",
      "updateAppointment",
      "bulkCreateAppointment",
    ]),
    ...clientActions(["fetchClientList"]),
    ...employeeActions(["getWithAvailability"]),
    ...serviceActions(["fetchServiceList"]),
    ...locationActions(["fetchLocationList"]),
    ...formActions(["fetchClientConsultationList"]),
    ...cartActions(["addToCart"]),
    validateForm(cb?: () => void) {
      const valid = (
        this.$refs.appointmentForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      // Logic for saving appointment
      let date: any = this.dateFormatted;

      date = (date as string).split("/");
      const [hr, min] = this.time.split(":");

      let currentDate = new Date(+date[2], +date[1] - 1, +date[0], +hr, +min);

      const businessTimeZone = (this.role?.business as Business).timezone;
      currentDate = this.convertToTimezone(currentDate, businessTimeZone);
      const startDate = currentDate.toISOString();

      const payload = {
        serviceIds: this.selectedServiceIds,
        clientId: this.clientId,
        businessId: (this.role?.business as Business)._id,
        employeeId: this.employeeId,
        notes: this.notes,
        startDate,
        location: this.location,
        consultationFormId: this.formId as string | undefined,
        durationInMinutes: this.duration,
      };

      if (!payload.consultationFormId) delete payload.consultationFormId;

      if (!this.isRecurring) {
        this.createAppointment(payload).then((appointment) => {
          if (appointment) {
            if (cb) cb();
            else this.$emit("data-saved", true);
          }
        });
      } else {
        const appts: {
          serviceIds: never[];
          clientId: string;
          businessId: string;
          employeeId: string;
          notes: string;
          startDate: string;
          location: string;
          consultationFormId: string | undefined;
          durationInMinutes: number;
        }[] = [];
        if (this.recurringPeriod === "week") {
          for (let i = 0; i < this.recurringDuration; i++) {
            const d = moment(startDate).add(i, "week").toISOString();
            appts.push({ ...payload, ...{ startDate: d } });
          }
        } else if (this.recurringPeriod === "month") {
          for (let i = 0; i < this.recurringDuration; i++) {
            const d = moment(startDate).add(i, "month").toISOString();
            appts.push({ ...payload, ...{ startDate: d } });
          }
        } else if (this.recurringPeriod === "14-day") {
          for (let i = 0; i < this.recurringDuration; i++) {
            const d = moment(startDate)
              .add(i * 14, "day")
              .toISOString();
            appts.push({ ...payload, ...{ startDate: d } });
          }
        } else if (this.recurringPeriod === "6-month") {
          for (let i = 0; i < this.recurringDuration; i++) {
            const d = moment(startDate)
              .add(i * 6, "month")
              .toISOString();
            appts.push({ ...payload, ...{ startDate: d } });
          }
        }

        this.bulkCreateAppointment(appts).then((appointments) => {
          if (appointments) this.$emit("data-saved", true);
        });
      }
    },
    convertToTimezone(date, timeZone) {
      // Get the date and time components in the target timezone
      const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: timeZone,
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      });

      const parts = formatter.formatToParts(date);
      const dateComponents = {} as any;

      for (const { type, value } of parts) {
        if (type !== "literal") {
          dateComponents[type] = value.padStart(2, "0");
        }
      }

      // Build a string in ISO 8601 format
      const y = dateComponents.year;
      const m = dateComponents.month;
      const d = dateComponents.day;
      const h = dateComponents.hour;
      const min = dateComponents.minute;
      const s = dateComponents.second;

      // Create a date string in the format 'YYYY-MM-DDTHH:mm:ss'
      const isoString = `${y}-${m}-${d}T${h}:${min}:${s}`;

      // Create a new Date object from the ISO string (interpreted in local timezone)
      const targetDate = new Date(isoString);

      return targetDate;
    },
    convertToEAT(date) {
      // East Africa Time offset from UTC in minutes (+3 hours)
      const eatOffset = -180; // Negative because getTimezoneOffset() returns the opposite sign
      // Current timezone offset from UTC in minutes
      const currentOffset = date.getTimezoneOffset();
      // Calculate the difference between EAT offset and current offset
      const offsetDifference = eatOffset - currentOffset;
      // Create a new Date adjusted by the offset difference
      return new Date(date.getTime() + offsetDifference * 60000);
    },
    resetForm() {
      this.date = "";
      this.time = "";
      if (this.inputTime) this.time = this.inputTime;
      if (this.inputDate) {
        this.date = this.inputDate;
        this.dateFormatted = this.formatDate(this.inputDate) as string;
      }
      this.employeeId = "";
      this.clientId = "";
      this.selectedServiceIds = [];
      this.notes = "";
      (
        this.$refs.appointmentForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
    addClient() {
      this.$emit("add-client", true);
    },
    deleteSlot() {
      //
    },
    searchService(q?: string) {
      const bid = (this.role.business as Business)._id;
      let params = `?businessId=${bid}`;
      if (q) params += `&q=${q}`;
      this.fetchServiceList(params);
    },
    searchClient(q: string) {
      const bid = (this.role.business as Business)._id;
      if (q) this.fetchClientList(`?businessId=${bid}&q=${q}`);
      //TODO: use debounce
      else this.fetchClientList(`?businessId=${bid}`);
    },
    selectService(serviceIds: Service["_id"][]) {
      const services: Service[] = [];
      serviceIds.map((id) => {
        const service = this.servicePage.docs.find(
          (s: Service) => s._id === id
        );
        services.push(service);
      });

      const service: Service = this.servicePage.docs.find(
        (s: Service) => s._id === serviceIds[serviceIds.length - 1]
      );

      if (service && service.advancedPricing.length > 1) {
        this.service = service;
        this.showServiceDurationSelectionDialog = true;
      } else if (service) {
        this.duration += service.advancedPricing[0]?.durationInMinutes ?? 0;
      }
      this.selectedServices = services;
    },
    searchLocation(q: string) {
      this.customLocation = q;
      if (q) {
        this.isLoadingLocations = true;
        const bid = (this.role.business as Business)._id;
        let params = `?businessId=${bid}&q=${q}`;
        this.fetchLocationList(params).then((locationPage) => {
          this.isLoadingLocations = false;
          if (locationPage) {
            this.locations = locationPage.docs.map((l: _Location) => l.name);
          }
        });
      }
    },
    fetchData() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        this.fetchServiceList(`?businessId=${bid}`);
        this.fetchClientList(`?businessId=${bid}`);
      }
    },
    selectDuration(duration: number) {
      this.duration += duration;
      this.showServiceDurationSelectionDialog = false;
    },
    selectForm(formId: string) {
      this.formId = formId;
      this.showConsultationFormDialog = false;
    },
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
    parseDate(date: string) {
      if (!date) return null;
      let d: string | string[] = date.split("/");

      d = `${d[2]}-${d[1]}-${d[0]}`;

      return moment(d).tz(tz).format("YYYY-MM-DD");
    },
    checkout() {
      this.validateForm(() => {
        this.$store.dispatch("cart/deleteCart");
        if (this.client) {
          this.$store.dispatch("cart/addCartClient", this.client);
        }
        const employee = this.employeePage.docs.find(
          (item) => item._id === this.employeeId
        );
        const _cartServices = this.selectedServices.map((_service: Service) => {
          let _price = 0;
          _service.advancedPricing.map((ap) => {
            const staffPrice = ap.staffPricing.find(
              (sp) => sp.jobTitle?._id === employee.jobTitle?._id
            );

            if (staffPrice) _price = staffPrice.price;
            else if (ap.durationInMinutes === this.duration) _price = ap.price;
          });

          let deposit = 0;
          if (
            _service.advancedPricing.length &&
            _service.deposit &&
            _service.requiresDeposit
          ) {
            _price = _service.advancedPricing[0].price;

            if (_service.deposit?.amountType === "percentage") {
              deposit = (_service.deposit?.amount / 100) * _price;
            } else {
              deposit = _service.deposit?.amount || 0;
            }
          }
          return {
            service: _service,
            quantity: 1,
            name: _service.name,
            unitPrice: _price,
            id: _service._id,
            sub_total: _price,
            staff: this.employeeId,
            appointmentTime: this.time,
            appointmentDate: this.date,
            deposit,
          };
        });
        // this.addToCart(
        //   this.selectedServices.map((service) => ({
        //     time: this.time,
        //     date: this.date,
        //     qty: 1,
        //     staff: this.employeePage.docs.find(
        //       (emp: Employee) => emp._id === this.employeeId
        //     ),
        //     ...service,
        //   }))
        // );
        this.$store
          .dispatch("cart/addItemListToCart", {
            items: _cartServices,
            itemType: "service",
          })
          .then(() => {
            this.$router.push("/checkout");
          });
      });
    },
    getEmployees() {
      if (
        this.duration &&
        this.role &&
        this.dateFormatted &&
        this.time &&
        this.selectedServiceIds.length
      ) {
        this.employeeId = "";
        const bid = (this.role.business as Business)._id;
        let date: any = this.dateFormatted;

        date = (date as string).split("/");
        const [hr, min] = this.time.split(":");

        const startDate = new Date(
          +date[2],
          +date[1] - 1,
          +date[0],
          +hr,
          +min
        ).toISOString();
        let params = `?businessId=${bid}&startDate=${startDate}&durationInMinutes=${this.duration}`;
        if (this.selectedServiceIds.length)
          params += `&serviceIds=${this.selectedServiceIds.join(
            "&serviceIds="
          )}`;
        this.getWithAvailability(params);
      }
    },
    payDeposit() {
      this.validateForm(() => {
        this.$store.dispatch("cart/deleteCart");
        if (this.client) {
          this.$store.dispatch("cart/addCartClient", this.client);
        }
        const employee = this.employeePage.docs.find(
          (item) => item._id === this.employeeId
        );
        const _cartServices = this.selectedServices.map((_service: Service) => {
          let _price = 0;
          _service.advancedPricing.map((ap) => {
            const staffPrice = ap.staffPricing.find(
              (sp) => sp.jobTitle?._id === employee.jobTitle?._id
            );

            if (staffPrice) _price = staffPrice.price;
            else if (ap.durationInMinutes === this.duration) _price = ap.price;
          });

          let deposit = 0;
          if (
            _service.advancedPricing.length &&
            _service.deposit &&
            _service.requiresDeposit
          ) {
            _price = _service.advancedPricing[0].price;

            if (_service.deposit?.amountType === "percentage") {
              deposit = (_service.deposit?.amount / 100) * _price;
            } else {
              deposit = _service.deposit?.amount || 0;
            }
          }
          return {
            service: _service,
            quantity: 1,
            name: _service.name,
            unitPrice: _price,
            id: _service._id,
            sub_total: _price,
            staff: this.employeeId,
            appointmentTime: this.time,
            appointmentDate: this.date,
            deposit,
          };
        });
        // this.addToCart(
        //   this.selectedServices.map((service) => ({
        //     time: this.time,
        //     date: this.date,
        //     qty: 1,
        //     staff: this.employeePage.docs.find(
        //       (emp: Employee) => emp._id === this.employeeId
        //     ),
        //     ...service,
        //   }))
        // );
        this.$store
          .dispatch("cart/addItemListToCart", {
            items: _cartServices,
            itemType: "service",
          })
          .then(() => {
            this.$router.push("/pay-deposit");
          });
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CALENDAR_APPOINTMENTS")) {
      this.$store.registerModule(
        "CALENDAR_APPOINTMENTS",
        appointmentStoreModule
      );
    }
    if (!this.$store.hasModule("EMPLOYEES_")) {
      this.$store.registerModule("EMPLOYEES_", employeeStoreModule);
    }
    if (!this.$store.hasModule("CLIENTS_")) {
      this.$store.registerModule("CLIENTS_", clientStoreModule);
    }
    if (!this.$store.hasModule("SERVICES_")) {
      this.$store.registerModule("SERVICES_", serviceStoreModule);
    }
    if (!this.$store.hasModule("APPOINTMENT_LOCATIONS")) {
      this.$store.registerModule("APPOINTMENT_LOCATIONS", locationStoreModule);
    }
    if (!this.$store.hasModule("CLIENT_CONSULTATION_FORMS")) {
      this.$store.registerModule(
        "CLIENT_CONSULTATION_FORMS",
        consultationFormStoreModule
      );
    }
    if (!this.$store.hasModule("CART_")) {
      this.$store.registerModule("CART_", checkout);
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule("EMPLOYEES_");
    // this.$store.unregisterModule("CALENDAR_APPOINTMENTS");
    // this.$store.unregisterModule("CLIENTS_");
    // this.$store.unregisterModule("SERVICES_");
    this.$store.unregisterModule("APPOINTMENT_LOCATIONS");
    this.$store.unregisterModule("CLIENT_CONSULTATION_FORMS");
  },
});
