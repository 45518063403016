import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Client, Feedback, Page } from "@/types";

type ClientState = {
  clientPage: Page<Client>;
  feedbackPage: Page<Feedback>;
  clientCount: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clientAggregate: { [key: string]: any }[];
  clients: Client[];
  newClients: Client[];
};

const client: Module<ClientState, unknown> = {
  namespaced: true,
  state: () => ({
    clientPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    feedbackPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    clientCount: 0,
    clientAggregate: [],
    clients: [],
    newClients: [],
  }),
  getters: {
    getClient: (state) => (clientId: string) =>
      state.clientPage.docs.find((c) => c._id === clientId),
    clientPage: (state) => state.clientPage,
    feedbackPage: (state) => state.feedbackPage,
    clientCount: (state) => state.clientCount,
    clientAggregate: (state) => state.clientAggregate,
    getNewClients: (state) => state.clients,
  },
  mutations: {
    ADD_CLIENT: (state, _client) => {
      let idx = -1;
      state.clientPage.docs.map((a, i) => {
        if (a._id === _client._id) idx = i;
      });
      if (idx === -1) state.clientPage.docs.push(_client);
      else Vue.set(state.clientPage.docs, idx, _client);
    },
    SET_CLIENT_PAGE: (state, list) => {
      state.clientPage = list;
    },
    SET_FEEDBACK_PAGE: (state, list) => {
      state.feedbackPage = list;
    },
    SET_CLIENT_COUNT(state, count) {
      state.clientCount = count;
    },
    SET_CLIENTS(state, _clients) {
      state.clients = _clients;
    },
    SET_CLIENT_AGGREGATE(state, aggregate) {
      state.clientAggregate = aggregate;
    },
    REMOVE_CLIENT(state, _client) {
      let idx = -1;
      state.clientPage.docs.map((a, i) => {
        if (a._id === _client._id) idx = i;
      });
      if (idx > -1) state.clientPage.docs.splice(idx, 1);
    },
    SET_NEW_CLIENTS(state, newClients) {
      state.newClients = newClients;
    },
  },
  actions: {
    async fetchClient(context, params = "") {
      return await api
        .get(`/v1/client${params}`)
        .then((response) => {
          context.commit("ADD_CLIENT", response.data.client);
          return response.data.client;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchClientList(context, params = "") {
      api
        .get(`/v1/client${params}`)
        .then((response) => {
          context.commit("SET_CLIENT_PAGE", response.data.clientPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchClientFeedback(context, params = "") {
      api
        .get(`/v1/feedback${params}`)
        .then((response) => {
          context.commit("SET_FEEDBACK_PAGE", response.data.feedbackPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchClientCount(context, params = "") {
      api
        .get(`/v1/client/count${params}`)
        .then((response) => {
          context.commit("SET_CLIENT_COUNT", response.data.clientCount);
          context.commit("SET_CLIENTS", response.data.clients);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchClientAggregate(context, params = "") {
      return await api
        .get(`/v1/client/aggregate${params}`)
        .then((response) => {
          context.commit("SET_CLIENT_AGGREGATE", response.data.clientAggregate);
          return response.data.clientAggregate;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createClient(context, payload) {
      return await api
        .post(`/v1/client`, payload)
        .then((response) => {
          context.commit("ADD_CLIENT", response.data.client);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client created",
            },
            { root: true }
          );
          return response.data.client;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateClient(context, data: { id: string; client: Client }) {
      return await api
        .put(`/v1/client/${data.id}`, data.client)
        .then((response) => {
          context.commit("ADD_CLIENT", response.data.client);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client updated",
            },
            { root: true }
          );
          return response.data.client;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteClient(context, id) {
      api
        .delete(`/v1/client${id}`, {
          headers: { "vendor-module": "clients.Profile" },
        })
        .then((response) => {
          context.commit("REMOVE_CLIENT", response.data.client);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async exportClients(context, params = "") {
      return await api
        .get(`/v1/client/export${params}`)
        .then((response) => response)
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateUserAddress(context, payload) {
      return await api
        .post("/v1/user/address", { ...payload })
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Address Updated",
            },
            { root: true }
          );
          return response;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchNewClients(context, { businessId, startDate, endDate }) {
      let url = `/v1/sales-ledger/new-clients?businessId=${businessId}`;
      if (startDate) {
        url += `&startDate=${startDate}`;
      }
      if (endDate) {
        url += `&endDate=${endDate}`;
      }

      try {
        const response = await api.get(url);
        context.commit("SET_NEW_CLIENTS", response.data.newClients.docs);
      } catch (error) {
        context.dispatch(
          "setToast",
          {
            title: "Request failed!",
            type: "error",
            text: error.response?.data?.error?.message,
          },
          { root: true }
        );
      }
    },
  },
};

export default client;
